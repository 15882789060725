<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Categorias</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form
      ref="form"
    >
      <v-row
        justify="start"
        no-gutters
        class="pa-2"
      >
        <v-col
          class="d-flex"
          lg="2"
          md="2"
        >
          <v-checkbox
            v-model="category.active"
            label="Ativo?"
          />
        </v-col>
      </v-row>

      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-model="category.name"
            outlined
            dense
            label="Categorias"
            :rules="$rules.required"
            required
            :items="category"
            item-text="name"
            item-value="id"
          />
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          sm="4"
        >
          <v-select
            v-model="category.targetProducts"
            prepend-icon="mdi-clipboard-text-search"
            :items="products"
            :rules="$rules.required"
            required
            item-text="title"
            label="Produtos-Cassems*"
            class="form-control"
            return-object
            multiple
            solo
          />
        </v-col>
      </v-row>
      <v-row
        justify="start"
        no-gutters
        class="pa-2 mt-0"
      >
        <v-col
          class="d-flex pr-5"
          lg="6"
          md="12"
          sm="12"
        >
          <v-text-field
            v-if="category.slug"
            :value="category.slug"
            outlined
            dense
            label="Slug"
            disabled
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row
      class="mt-10"
      justify="center"
      no-gutters
    >
      <v-card-actions>
        <v-btn
          color="secondary"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :loading="isLoading"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
  </v-card>
</template>

<script>

import CategoryService from '../../../services/category.service';
import TargetProductService from '../../../services/target-product.service';

export default {
  name: 'CategoryForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      category: {},
      products: [],
      isLoading: false,
    };
  },

  async created() { if (this.populateWith.id) { this.category = this.populateWith; } await this.loadProduct(); },

  methods: {
    close() {
      this.$emit('close-dialog');
    },

    async loadProduct() {
      const data = await TargetProductService.findAll();
      this.products = data;
    },

    async submit() {
      this.isLoading = true;
      if (this.$refs.form.validate()) {
        if (this.populateWith.id) {
          try {
            await CategoryService.updateCategory({
              ...this.category,
              id: this.category.id,
              title: this.category.title,
              active: this.category.active,
              targetProducts: this.category.targetProducts,
            });
            this.$emit('category-edited', {
              ...this.category,
              id: this.category.id,
              title: this.category.title,
              active: this.category.active,
              targetProducts: this.category.targetProducts,
            });
            this.isLoading = false;
            this.$toast.success('Categoria editada com sucesso');
            this.close();
          } catch (e) {
            this.isLoading = false;
            this.$handleHttpError(e);
          }
        } else {
          try {
            await CategoryService.createCategory({
              ...this.category,
              title: this.category.title,
              active: this.category.active,
              targetProducts: this.category.targetProducts,
            });
            this.$emit('category-added', {
              ...this.category,
              title: this.category.title,
              active: this.category.active,
              targetProducts: this.category.targetProducts,
            });
            this.isLoading = false;
            this.$toast.success('Categoria cadastrada com sucesso');
          } catch (e) {
            this.isLoading = false;
            this.$handleHttpError(e);
          }
        }
      } else {
        this.isLoading = false;
        this.$toast.error('Verifique os campos e tente novamente');
      }
    },
  },
};
</script>
